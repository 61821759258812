import { Link } from "@tanstack/react-location";

export default function Nav() {
  return (
    <div className='flex md:mx-16 items-center'>
      <a href='/'>
        <div className='md:text-xl font-semibold'>anjil.</div>
      </a>
      <div className='flex-1'></div>
      <div className='flex'>
        <ul className='flex font-semibold md:text-xl items-center space-x-12'>
          {/* <Link to='work'>
            <li>Work</li>
          </Link> */}
          <Link to='about'>
            <li className='hidden md:block'>About</li>
          </Link>
          {/* <Link to='contact'>
            <li className='hidden md:block'>Contact</li>
          </Link> */}
          {/* <Link to='projects'>
            <li>Projects</li>
          </Link> */}
          <a
            target='_blank'
            href='https://enlighten.anjil.xyz/'
            rel='noreferrer'
          >
            <div className='px-6 cursor-pointer  hover:text-blue-600 py-2 bg-white text-black'>
              Join My Newsletter
            </div>
          </a>
        </ul>
      </div>
    </div>
  );
}
