import clamphook from "../Images/clamphook.png";

function Clamphook() {
  return (
    <div className=' md:max-w-7xl md:mx-auto'>
      <img src={clamphook} alt='' />
      <div className='flex gap-8 md:py-12  text-center md:text-left'>
        <div className='flex-col w-1/3'>
          <div className=''>
            <div className='md:text-3xl'>
              Web Application & Mobile Application{" "}
            </div>
            <div>Product Designer</div>
          </div>
        </div>
        <div className='w-3/4 text-2xl'>
          The Create with Google website is a public access point for the entire
          'maker' community who want clear creative considerations and examples
          of how to use product, platforms, and technologies in Google’s
          ecosystem. The site also houses tools to help action work on Google's
          platforms. Unlike the prescriptive Facebook Creative Hub, our platform
          has been created to work seamlessly for the creative industry
        </div>
      </div>
    </div>
  );
}

export default Clamphook;
