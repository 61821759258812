// import Hero from "./Components/Hero";
import MainLayout from "./Components/MainLayout";

function App() {
  return (
    <div>
      <MainLayout />
    </div>
  );
}

export default App;
