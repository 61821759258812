function About() {
  return (
    <div className=' md:max-w-7xl md:p-0 p-8  mx-auto justify-center flex-col'>
      <div className='  flex'>
        <p className='md:text-7xl text-4xl pt-24  sfprobold  text-left md:text-left md:py-12  text-white '>
          Designer, Developer &amp; Writer
        </p>
      </div>
      {/* <div className="md:h-36 px-12 md:mx-72 dark:text-white text-black">
    <h2 className=" ">
      1. Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis ipsa
      sint beatae ea id libero voluptatem aperiam, laudantium culpa animi
      consectetur nulla fugit voluptate esse, provident laboriosam obcaecati
      est enim.
    </h2>
    <h2 className=" ">
      1. Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis ipsa
      sint beatae ea id libero voluptatem aperiam, laudantium culpa animi
      consectetur nulla fugit voluptate esse, provident laboriosam obcaecati
      est enim.
    </h2>
  </div> */}
      <div className='dark:text-white items-center text-black '>
        <h1 className='sfprolight md:text-3xl pt-12 text-xl pb-12'>
          I love helping brands, companies and organizations connect with their
          audience through good design. Its always a pleasure to see companies
          grow through the power of design.
        </h1>
      </div>

      {/* Main Skills & Values */}
      <div className='md:grid flex py-12 flex-wrap  md:gap-24 gap-24 grid-cols-2 dark:text-white text-black'>
        <div className=''>
          <div className='text-5xl  sfproregular'>Main Skills</div>
          <ul className='py-12'>
            <li className='pb-4'>
              <div className='text-2xl sfprosemibold'>
                User experience design(ux)
              </div>
              <div className='text-2xl sfprolight text-gray-400 jost400'>
                Delight the user and make it work.
              </div>
            </li>
            <li className='pb-4'>
              <div className='text-2xl sfprosemibold'>
                Web and user interface design
              </div>
              <div className='text-2xl sfprolight text-gray-400 '>
                Websites, web expriences, ...
              </div>
            </li>
            <li className='pb-4'>
              <div className='text-2xl'>Interaction design</div>
              <div className='text-2xl sfprolight text-gray-400 jost400'>
                I like to move it move it.
              </div>
            </li>
            <li className='pb-4'>
              <div className='text-2xl sfprosemibold'>Branding</div>
              <div className='text-2xl sfprolight text-gray-400 jost400'>
                Logo & identity design.
              </div>
            </li>
          </ul>
        </div>
        <div className=''>
          <div className='text-5xl sfproregular'>Values.</div>
          <ul className='py-12'>
            <li className='pb-4'>
              <div className='text-2xl sfprosemibold'>
                Good design is good business
              </div>
              <div className='text-2xl sfprolight text-gray-400 jost400'>
                It's not only about beauty, it has to work.
              </div>
            </li>
            <li className='pb-4'>
              <div className='text-2xl sfprosemibold'>Never not learning</div>
              <div className='text-2xl sfprolight text-gray-400 jost400'>
                There's always something new to learn
              </div>
            </li>
            <li className='pb-4'>
              <div className='text-2xl sfprosemibold'>
                Get to the root of the problem
              </div>
              <div className='text-2xl sfprolight text-gray-400 jost400'>
                To come up with the best solution
              </div>
            </li>
            <li className='pb-4'>
              <div className='text-2xl sfprosemibold'>Keep it simple</div>
              <div className='text-2xl sfprolight text-gray-400 jost400'>
                Why make it complicated?
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className='flex mx-72 py-32  dark:text-white text-black'></div>
    </div>
  );
}

export default About;
