import Header from "./Header";
// import HeadNew from "./HeadNew";
import Work from "./Work";

export default function Landing() {
  return (
    <div className='md:max-w-8xl mx-auto md:px-0 px-12  container'>
      <div className=''>
        <Header />
      </div>
      {/* <HeadNew /> */}
      {/* <div className='my-36 flex'></div>
      <Work /> */}
    </div>
  );
}
