import { Route, ReactLocation } from "@tanstack/react-location";
import About from "../pages/About";
import Contact from "../pages/Contact";
import NotFound from "../Components/NotFound";
import Landing from "../Components/Landing";
import Clamphook from "../Components/Clamphook";
import Work from "../Components/Work";
import { Projects } from "../Components/Projects";

export const routes: Route[] = [
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "about",
    element: <About />,
  },
  {
    path: "contact",
    element: <Contact />,
  },
  // {
  //   path: "work",
  //   element: <Work />,
  // },
  {
    path: "clamphook",
    element: <Clamphook />,
  },

  {
    path: "projects",
    element: <Projects />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export const location = new ReactLocation();
