import React from "react";
import { Router, Outlet } from "@tanstack/react-location";

import { routes, location } from "../Route/router";
// import Hero from "./Hero";
import Nav from "./Nav";
import Footer from "./Footer";

export default function MainLayout() {
  return (
    <Router routes={routes} location={location}>
      <div className=''>
        <div className=' w-full p-8 top-0 sticky'>
          <Nav />
        </div>
        <div className=''>
          <Outlet />
        </div>
        {/* <div>
          <Footer />
        </div> */}
      </div>
    </Router>
  );
}
